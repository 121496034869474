"use client"

import React, { useState } from "react"
import { Icon } from "@/components/ui/icon"
import type { IconName } from "@/components/ui/icon"
import Link from "next/link"
import {
  affiliationMap,
  type Affiliation,
  affiliations,
} from "@/affiliations-config"
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet"
import { Separator } from "@/components/ui/separator"
import { HeroForm } from "@/forms/hero-form"
import { Logger } from "next-axiom"


const LOG = new Logger({
  source: "MobileQuickLinkMenu",
})

export default function MobileQuickMenu({
  affiliation,
}: {
  affiliation: Affiliation
}) {
  const [openLocateUs, setOpenLocateUs] = useState<boolean>(false)
  const [openBookAppointment, setOpenBookAppointment] = useState<boolean>(false)

  const menuItems: { name: IconName; label: string; to: string }[] = [
    { name: "app--mobileMenuDoctor", label: "Doctor", to: "/doctors" },
    {
      name: "app--mobileMenuAppointments",
      label: "Book Appt",
      to: "/appointments",
    },
    { name: "app--mobileMenuEnquiry", label: "Enquire", to: "/contactus" },
    { name: "app--care", label: "Health Checks", to: "/health-checks" },
    { name: "app--mobileMenuLocateus", label: "Locate Us", to: "/contactus" },
  ]

  return (
    <div className="fixed inset-x-0 bottom-0 z-50 flex h-16 justify-between bg-[#F9F9FE] shadow-inner sm:hidden">
      <Link
        href={`/${affiliation}/doctors`}
        className="flex-1 py-2 text-center"
      >
        <Icon name="app--mobileMenuDoctor" className="mx-auto h-6 w-6" />
        <div className="text-xs font-medium leading-3">Doctors</div>
      </Link>
      <Sheet open={openBookAppointment} onOpenChange={setOpenBookAppointment}>
        <SheetTrigger asChild>
          <div className="flex-1 py-2 text-center">
            <Icon
              name="app--mobileMenuAppointments"
              className="mx-auto h-6 w-6"
            />
            <div className="text-xs font-medium leading-3">Book Appt</div>
          </div>
        </SheetTrigger>
        <SheetContent side="left" className="w-full p-0">
          <SheetTitle className="border-b p-4">
            <Link href={`/`}>
              <Icon
                name="app--sterlings-logo"
                className="h-8 w-32"
                onClick={() => setOpenBookAppointment(false)}
              />
            </Link>
          </SheetTitle>
          <div className="py-4">
            <HeroForm affiliation={affiliation} />
          </div>
        </SheetContent>
      </Sheet>

      <Link
        href={`/${affiliation}/health-checks`}
        className="flex-1 py-2 text-center"
      >
        <Icon name="app--care" className="mx-auto h-6 w-6" fill="#000000" />
        <div className="text-xs font-medium leading-3">Health Checks</div>
      </Link>

      <Link 
        href="tel:01865611125" 
        className="flex-1 py-2 text-center" 
        onClick={() => LOG.info('Call button clicked')}
      >
        <Icon name="app--mobileMenuCall" className="mx-auto h-6 w-6" />
        <div className="text-xs font-medium leading-3">Call Us</div>
      </Link>

      <Sheet open={openLocateUs} onOpenChange={setOpenLocateUs}>
        <SheetTrigger asChild>
          <div className="flex-1 py-2 text-center">
            <Icon name="app--mobileMenuLocateus" className="mx-auto h-6 w-6" />
            <div className="text-xs font-medium leading-3">Locate Us</div>
          </div>
        </SheetTrigger>
        <SheetContent side="bottom" className="w-full p-0">
          <SheetTitle className="border-b p-4">
            <Link href={`/`}>
              <Icon
                name="app--sterlings-logo"
                className="h-8 w-32"
                onClick={() => setOpenLocateUs(false)}
              />
            </Link>
          </SheetTitle>
          <ul className="list-none space-y-2 p-4">
            {Array.from(affiliationMap.entries())
              .filter(([key]) => key !== affiliations.defaultHospital)
              .map(([key, value]) => (
                <li
                  key={key}
                  className="space-y-2 text-lg font-medium text-brand-500"
                >
                  <a href={value.mapLink} target="_blank">
                    {value.name}
                  </a>
                  <Separator />
                </li>
              ))}
          </ul>
        </SheetContent>
      </Sheet>
    </div>
  )
}
